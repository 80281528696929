import { type VariantProps, cva } from "cva";
import React, { useId } from "react";
import { VStack } from "../../core/Stack";
import { cn } from "../../utils";
import { Label } from "../Label";

const inputWrapperStyles = cva(
  "tw-flex tw-relative tw-w-full tw-rounded-md tw-bg-white dark:tw-bg-rad-blue-300/50 tw-shadow-sm tw-ring-1 tw-ring-inset focus-within:tw-ring-2 focus-within:tw-ring-inset",
  {
    variants: {
      isInvalid: {
        true: "tw-ring-red-600 focus-within:tw-ring-red-600",
        false:
          "tw-ring-zinc-300 dark:tw-ring-rad-blue-200/50 focus-within:tw-ring-zinc-700 dark:focus-within:tw-bg-rad-blue-300/50",
      },
    },
  },
);

const inputStyles = cva(
  "tw-block tw-w-full tw-flex-1 tw-border-0 tw-bg-transparent tw-text-zinc-900 dark:tw-text-white placeholder:tw-text-zinc-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6",
  {
    variants: {
      colorScheme: {},
      size: {
        xs: ["tw-px-2", "tw-py-0.5", "tw-text-xs", "gap-x-1"],
        sm: ["tw-px-2", "tw-py-1", "tw-text-sm", "gap-x-1.5"],
        md: ["tw-px-2.5", "tw-py-1.5", "tw-text-sm", "gap-x-1.5"],
        lg: ["tw-px-3", "tw-py-2", "tw-text-sm", "gap-x-1.5"],
        xl: ["tw-px-3.5", "tw-py-2.5", "tw-text-md", "gap-x-2"],
      },
    },
    defaultVariants: {
      size: "md",
    },
  },
);

const inputElementStyles = cva(
  "tw-absolute tw-inset-y-0 tw-flex tw-items-center",
  {
    variants: {
      position: {
        right: "tw-right-0",
        left: "tw-left-0",
      },
      size: {
        xs: [],
        sm: [],
        md: [],
        lg: [],
        xl: [],
      },
    },
    compoundVariants: [
      {
        position: "left",
        size: "xs",
        className: "tw-pl-1",
      },
      {
        position: "left",
        size: "sm",
        className: "tw-pl-1",
      },
      {
        position: "left",
        size: "md",
        className: "tw-pl-1.5",
      },
      {
        position: "left",
        size: "lg",
        className: "tw-pl-2",
      },
      {
        position: "left",
        size: "xl",
        className: "tw-pl-2.5",
      },
      {
        position: "right",
        size: "xs",
        className: "tw-pr-1",
      },
      {
        position: "right",
        size: "sm",
        className: "tw-pr-1",
      },
      {
        position: "right",
        size: "md",
        className: "tw-pr-1.5",
      },
      {
        position: "right",
        size: "lg",
        className: "tw-pr-2",
      },
      {
        position: "right",
        size: "xl",
        className: "tw-pr-2.5",
      },
    ],
    defaultVariants: {
      size: "md",
    },
  },
);

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">,
    VariantProps<typeof inputStyles> {
  rightElement?: React.ReactNode;
  leftElement?: React.ReactNode;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, size, rightElement, leftElement, ...props }, ref) => {
    return (
      <div
        className={inputWrapperStyles({
          isInvalid: Boolean(props["aria-invalid"]),
        })}
      >
        {leftElement && (
          <span
            className={cn(
              inputElementStyles({ position: "left", size }),
              "tw-pointer-events-none",
            )}
          >
            {leftElement}
          </span>
        )}
        <input
          ref={ref}
          className={cn(inputStyles({ size }), className)}
          {...props}
        />
        {rightElement && (
          <span
            className={cn(
              inputElementStyles({ position: "right", size }),
              "tw-pointer-events-none",
            )}
          >
            {rightElement}
          </span>
        )}
      </div>
    );
  },
);

export const InputWithLabel = React.forwardRef<HTMLInputElement, InputProps>(
  ({ children, id, size, ...other }, ref) => {
    let elemId = useId();
    if (id) {
      elemId = id;
    }
    return (
      <VStack spacing={1}>
        <Label htmlFor={elemId} size={size}>
          {children}
        </Label>
        <Input size={size} {...other} id={elemId} ref={ref} />
      </VStack>
    );
  },
);
