import { type VariantProps, cva } from "cva";
import React from "react";
import { cn } from "../../utils";

const selectStyles = cva(
  "tw-flex tw-relative tw-pr-4 tw-border-0 tw-w-full tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset focus-within:tw-ring-2 focus-within:tw-ring-inset dark:tw-bg-rad-blue-500 dark:tw-text-white dark:tw-ring-rad-blue-300",
  {
    variants: {
      isInvalid: {
        true: "tw-ring-red-600 focus-within:tw-ring-red-600",
        false: "tw-ring-zinc-300 focus-within:tw-ring-zinc-600",
      },
      colorScheme: {},
      size: {
        xs: ["tw-px-2", "tw-pl-0.5", "tw-pr-6", "tw-text-xs", "gap-x-1"],
        sm: ["tw-px-2", "tw-pl-1", "tw-pr-6.5", "tw-text-sm", "gap-x-1.5"],
        md: ["tw-px-2.5", "tw-pl-1.5", "tw-pr-7", "tw-text-sm", "gap-x-1.5"],
        lg: ["tw-px-3", "tw-pl-2", "tw-pr-7.5", "tw-text-sm", "gap-x-1.5"],
        xl: ["tw-px-3.5", "tw-pl-2.5", "tw-pr-8", "tw-text-md", "gap-x-2"],
      },
    },
    defaultVariants: {
      size: "md",
    },
  },
);

export interface SelectProps
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "size">,
    VariantProps<typeof selectStyles> {}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ className, size, ...props }, ref) => {
    return (
      <select
        ref={ref}
        className={cn(
          selectStyles({ size, isInvalid: Boolean(props["aria-invalid"]) }),
          className,
        )}
        {...props}
      />
    );
  },
);
