import { type VariantProps, cva } from "cva";
import type React from "react";
import { cn } from "../../utils";

const badge = cva("tw-inline-flex tw-items-center tw-ring-inset", {
  variants: {
    variant: {
      solid: "tw-ring-1",
      bordered: "tw-ring-2",
      flat: "tw-ring-zinc-700/30 tw-ring-1 tw-text-zinc-700 dark:tw-ring-zinc-300/30 dark:tw-text-zinc-300",
    },
    pill: {
      true: "tw-rounded-2xl",
      false: "tw-rounded-md",
    },
    uppercase: {
      true: "tw-uppercase tw-text-[10.5px]/[1rem] tw-font-bold",
      false: "tw-text-xs tw-font-medium",
    },
    withDot: {
      true: "",
      false: "",
    },
    colorScheme: {
      indigo: [],
      red: [],
      slate: [],
      green: [],
      yellow: [],
      blue: [],
      pink: [],
      purple: [],
      teal: [],
      zinc: [],
    },
    size: {
      xs: "tw-px-1 tw-py-0 tw-gap-x-0.5",
      sm: "tw-px-1.5 tw-py-0.5 tw-gap-x-1",
      md: "tw-px-2 tw-py-1 tw-gap-x-1.5",
      lg: "tw-px-2.5 tw-py-1.5 tw-gap-x-2",
      xl: "tw-px-3.5 tw-py-2 !tw-text-sm tw-gap-x-2.5",
    },
  },
  compoundVariants: [
    {
      variant: "solid",
      colorScheme: "indigo",
      className: "tw-bg-indigo-50 tw-text-indigo-700 tw-ring-indigo-700/10",
    },
    {
      variant: "solid",
      colorScheme: "red",
      className: "tw-bg-red-50 tw-text-red-700 tw-ring-red-600/10",
    },
    {
      variant: "solid",
      colorScheme: "slate",
      className: "tw-bg-slate-50 tw-text-slate-600 tw-ring-slate-500/10",
    },
    {
      variant: "solid",
      colorScheme: "teal",
      className: "tw-bg-teal-50 tw-text-teal-700 tw-ring-teal-600/10",
    },
    {
      variant: "solid",
      colorScheme: "zinc",
      className: "tw-bg-zinc-50 tw-text-zinc-600 tw-ring-zinc-500/10",
    },
    {
      variant: "solid",
      colorScheme: "green",
      className: "tw-bg-green-50 tw-text-green-600 tw-ring-green-600/10 ",
    },
    {
      variant: "solid",
      colorScheme: "blue",
      className: "tw-bg-blue-50 tw-text-blue-700 tw-ring-blue-700/10",
    },
    {
      variant: "solid",
      colorScheme: "yellow",
      className: "tw-bg-yellow-50 tw-text-yellow-800 tw-ring-yellow-600/10",
    },
    {
      variant: "solid",
      colorScheme: "pink",
      className: "tw-bg-pink-50 tw-text-pink-700 tw-ring-pink-700/10",
    },
    {
      variant: "solid",
      colorScheme: "purple",
      className: "tw-bg-purple-50 tw-text-purple-700 tw-ring-purple-700/10",
    },
    {
      variant: "bordered",
      colorScheme: "indigo",
      className:
        "tw-text-indigo-600 tw-ring-indigo-500 dark:tw-ring-indigo-500 dark:tw-text-indigo-400",
    },
    {
      variant: "bordered",
      colorScheme: "red",
      className:
        "tw-text-red-600 tw-ring-red-500 dark:tw-ring-red-500 dark:tw-text-red-400",
    },
    {
      variant: "bordered",
      colorScheme: "slate",
      className: "tw-text-slate-600 tw-ring-slate-500",
    },
    {
      variant: "bordered",
      colorScheme: "green",
      className:
        "tw-text-green-700 tw-ring-green-500 dark:tw-text-green-500 dark:tw-ring-green-400",
    },
    {
      variant: "bordered",
      colorScheme: "blue",
      className:
        "tw-text-blue-600 tw-ring-blue-500 dark:tw-ring-blue-400 dark:tw-text-blue-400",
    },
    {
      variant: "bordered",
      colorScheme: "yellow",
      className:
        "tw-text-yellow-700 tw-ring-yellow-500 dark:tw-text-yellow-500 dark:tw-ring-yellow-400",
    },
    {
      variant: "bordered",
      colorScheme: "pink",
      className:
        "tw-text-pink-600 tw-ring-pink-500 dark:tw-text-pink-400 dark:tw-ring-pink-400",
    },
    {
      variant: "bordered",
      colorScheme: "purple",
      className:
        "tw-text-purple-600 tw-ring-purple-500 dark:tw-ring-purple-400 dark:tw-text-purple-400",
    },
    {
      variant: "bordered",
      colorScheme: "teal",
      className:
        "tw-text-teal-600 tw-ring-teal-500 dark:tw-ring-teal-400 dark:tw-text-teal-400",
    },
    {
      variant: "bordered",
      colorScheme: "zinc",
      className: "tw-text-zinc-600 tw-ring-zinc-500",
    },
  ],
  defaultVariants: {
    variant: "solid",
    colorScheme: "zinc",
    size: "md",
    pill: false,
    uppercase: false,
  },
});

const dotStyles = cva("", {
  variants: {
    colorScheme: {
      indigo: "tw-fill-indigo-400",
      red: "tw-fill-red-400",
      slate: "tw-fill-slate-400",
      green: "tw-fill-green-400",
      yellow: "tw-fill-yellow-400",
      blue: "tw-fill-blue-400",
      pink: "tw-fill-pink-400",
      purple: "tw-fill-purple-400",
      teal: "tw-fill-teal-400",
      zinc: "tw-fill-zinc-400",
    },
    size: {
      xs: "tw-h-0.5 tw-w-0.5",
      sm: "tw-h-1 tw-w-1",
      md: "tw-h-1.5 tw-w-1.5",
      lg: "tw-h-2 tw-w-2",
      xl: "tw-h-2.5 tw-w-2.5",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

export interface BadgeProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof badge> {}

export interface DotProps
  extends React.SVGProps<SVGElement>,
    VariantProps<typeof dotStyles> {}

export const Dot = ({ className, ...props }: DotProps) => {
  return (
    <svg
      className={cn(dotStyles(props), className)}
      viewBox="0 0 6 6"
      aria-hidden="true"
    >
      <circle cx={3} cy={3} r={3} />
    </svg>
  );
};

export const Badge: React.FC<BadgeProps> = ({
  className,
  variant,
  size,
  pill,
  uppercase,
  colorScheme,
  children,
  withDot,
  ...props
}) => {
  return (
    <span
      className={cn(
        badge({ withDot, size, pill, variant, colorScheme, uppercase }),
        className,
      )}
      {...props}
    >
      {withDot && <Dot size={size} colorScheme={colorScheme} />}
      {children}
    </span>
  );
};

Badge.displayName = "Badge";
